<template>
  <div class="contact-page">
    <section class="section contact-hero">
      <div class="container">
        <h1 class="section-title">Get In Touch</h1>
        <p class="contact-description">
          I'm always open to discussing new projects, creative ideas or opportunities to be part of your vision.
        </p>
      </div>
    </section>
    
    <section class="section contact-content">
      <div class="container">
        <div class="contact-grid">
          <div class="contact-info">
            <div class="contact-card">
              <div class="contact-card-icon">
                <i class="fas fa-map-marker-alt"></i>
              </div>
              <div class="contact-card-content">
                <h3>Location</h3>
                <p>New York, NY, USA</p>
              </div>
            </div>
            
            <div class="contact-card">
              <div class="contact-card-icon">
                <i class="fas fa-envelope"></i>
              </div>
              <div class="contact-card-content">
                <h3>Email</h3>
                <p>hello@paulagustin.com</p>
              </div>
            </div>
            
            <div class="contact-card">
              <div class="contact-card-icon">
                <i class="fas fa-phone"></i>
              </div>
              <div class="contact-card-content">
                <h3>Phone</h3>
                <p>+1 (555) 123-4567</p>
              </div>
            </div>
            
            <div class="contact-card">
              <div class="contact-card-icon">
                <i class="fas fa-clock"></i>
              </div>
              <div class="contact-card-content">
                <h3>Working Hours</h3>
                <p>Monday-Friday: 9am - 5pm</p>
              </div>
            </div>
            
            <div class="contact-social">
              <h3>Connect With Me</h3>
              <div class="social-icons">
                <a href="https://github.com/CardsOnHudson" target="_blank" class="social-link">
                  <i class="fab fa-github"></i>
                </a>
                <a href="https://www.linkedin.com/in/pagustin/" target="_blank" class="social-link">
                  <i class="fab fa-linkedin"></i>
                </a>
                <a href="https://bsky.app/profile/paulagustin.com" target="_blank" class="social-link">
                  <i class="fab fa-bluesky"></i>  
                </a>
              </div>
            </div>
          </div>
          
          <div class="contact-form-container">
            <h2>Send Me a Message</h2>
            <p class="form-description">
              Feel free to get in touch with me. I am always open to discussing new projects, creative ideas or opportunities to be part of your visions.
            </p>
            
            <form class="contact-form" @submit.prevent="submitForm">
              <div class="form-grid">
                <div class="form-group">
                  <label for="name" class="form-label">Your Name</label>
                  <input 
                    type="text" 
                    id="name" 
                    class="form-input" 
                    v-model="form.name" 
                    :class="{ 'error': errors.name }" 
                    required
                  >
                  <span v-if="errors.name" class="error-message">{{ errors.name }}</span>
                </div>
                
                <div class="form-group">
                  <label for="email" class="form-label">Your Email</label>
                  <input 
                    type="email" 
                    id="email" 
                    class="form-input" 
                    v-model="form.email" 
                    :class="{ 'error': errors.email }" 
                    required
                  >
                  <span v-if="errors.email" class="error-message">{{ errors.email }}</span>
                </div>
              </div>
              
              <div class="form-group">
                <label for="subject" class="form-label">Subject</label>
                <input 
                  type="text" 
                  id="subject" 
                  class="form-input" 
                  v-model="form.subject" 
                  :class="{ 'error': errors.subject }" 
                  required
                >
                <span v-if="errors.subject" class="error-message">{{ errors.subject }}</span>
              </div>
              
              <div class="form-group">
                <label for="message" class="form-label">Your Message</label>
                <textarea 
                  id="message" 
                  class="form-textarea" 
                  v-model="form.message" 
                  :class="{ 'error': errors.message }" 
                  required
                ></textarea>
                <span v-if="errors.message" class="error-message">{{ errors.message }}</span>
              </div>
              
              <div class="form-submit">
                <button 
                  type="submit" 
                  class="btn" 
                  :disabled="submitting"
                >
                  <span v-if="!submitting">Send Message</span>
                  <span v-else>Sending...</span>
                </button>
              </div>
              
              <div v-if="formStatus.message" class="form-status" :class="{ 'success': formStatus.success, 'error': !formStatus.success }">
                {{ formStatus.message }}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import axios from 'axios';

const form = reactive({
  name: '',
  email: '',
  subject: '',
  message: ''
});

const errors = reactive({
  name: '',
  email: '',
  subject: '',
  message: ''
});

const submitting = ref(false);
const formStatus = reactive({
  success: false,
  message: ''
});

const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validateForm = () => {
  let isValid = true;
  
  // Reset errors
  errors.name = '';
  errors.email = '';
  errors.subject = '';
  errors.message = '';
  
  if (!form.name.trim()) {
    errors.name = 'Name is required';
    isValid = false;
  }
  
  if (!form.email.trim()) {
    errors.email = 'Email is required';
    isValid = false;
  } else if (!validateEmail(form.email)) {
    errors.email = 'Please enter a valid email address';
    isValid = false;
  }
  
  if (!form.subject.trim()) {
    errors.subject = 'Subject is required';
    isValid = false;
  }
  
  if (!form.message.trim()) {
    errors.message = 'Message is required';
    isValid = false;
  } else if (form.message.trim().length < 10) {
    errors.message = 'Message must be at least 10 characters long';
    isValid = false;
  }
  
  return isValid;
};

const submitForm = async () => {
  if (!validateForm()) return;
  
  submitting.value = true;
  formStatus.message = '';
  
  try {
    // Log the attempt
    console.log('Submitting contact form:', JSON.stringify(form));
    
    // Get the base URL using the current host
    const getApiBaseUrl = () => {
      // Get the current hostname
      const hostname = window.location.hostname;
      
      // Check if we're in development/Replit environment
      if (hostname === 'localhost' || hostname === '0.0.0.0' || hostname.includes('.repl.co')) {
        return `http://${hostname}:5000/api/contact`;
      }
      
      // In production, use the current domain
      return `${window.location.protocol}//${hostname}/api/contact`;
    };
    
    const contactEndpoint = getApiBaseUrl();
    console.log('Using API endpoint:', contactEndpoint);
    
    console.log('Sending request to:', contactEndpoint);
    
    const response = await axios.post(contactEndpoint, form, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
    
    console.log('Response received:', response);
    
    if (response.data && response.status === 200) {
      // Always treat 200 response as success
      formStatus.success = true;
      formStatus.message = response.data.message || 'Your message has been sent successfully! I will get back to you soon.';
      
      // Reset form
      form.name = '';
      form.email = '';
      form.subject = '';
      form.message = '';
      
      console.log('Form submitted successfully');
    } else {
      formStatus.success = false;
      formStatus.message = (response.data && response.data.message) || 'Something went wrong. Please try again.';
      console.log('Form submission failed');
    }
  } catch (error) {
    console.error('Error submitting form:', error);
    if (error.response) {
      console.error('Response error:', error.response.status, error.response.data);
    } else if (error.request) {
      console.error('Request error:', error.request);
    } else {
      console.error('Error details:', error.message);
    }
    
    formStatus.success = false;
    formStatus.message = 'There was an error sending your message. Please try again later or contact me directly at hello@paulagustin.com';
  } finally {
    submitting.value = false;
  }
};
</script>

<script>
export default {
  name: 'ContactView'
}
</script>

<style scoped>
.contact-page {
  padding-top: 80px;
}

.contact-hero {
  padding-top: 100px;
}

.contact-description {
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
  font-size: 1.2rem;
  color: var(--light-text-color);
}

.contact-content {
  background-color: white;
}

.contact-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 50px;
}

/* Contact Info Section */
.contact-info {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.contact-card {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.contact-card-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(52, 152, 219, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  color: var(--primary-color);
  flex-shrink: 0;
}

.contact-card-content h3 {
  font-size: 1.1rem;
  margin-bottom: 5px;
}

.contact-card-content p {
  color: var(--light-text-color);
}

.contact-social {
  margin-top: 20px;
}

.contact-social h3 {
  font-size: 1.1rem;
  margin-bottom: 15px;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-link {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(52, 152, 219, 0.1);
  color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  transition: all 0.3s ease;
}

.social-link:hover {
  background-color: var(--primary-color);
  color: white;
}

/* Contact Form Section */
.contact-form-container {
  background-color: var(--background-color);
  padding: 40px;
  border-radius: 8px;
  box-shadow: var(--card-shadow);
}

.contact-form-container h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: var(--primary-color);
}

.form-description {
  margin-bottom: 30px;
  color: var(--light-text-color);
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
}

.form-submit {
  margin-top: 30px;
}

.form-status {
  margin-top: 20px;
  padding: 15px;
  border-radius: 4px;
  text-align: center;
}

.form-status.success {
  background-color: rgba(46, 204, 113, 0.1);
  color: #27ae60;
}

.form-status.error {
  background-color: rgba(231, 76, 60, 0.1);
  color: #e74c3c;
}

/* Map Section */
.map-section {
  padding: 0;
}

.map-container {
  height: 400px;
  width: 100%;
}

.map-placeholder {
  height: 100%;
  background-color: rgba(52, 152, 219, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
  font-size: 3rem;
}

.map-placeholder p {
  font-size: 1rem;
  margin-top: 15px;
}

/* Form error states */
.form-input.error,
.form-textarea.error {
  border-color: var(--accent-color);
}

.error-message {
  color: var(--accent-color);
  font-size: 0.85rem;
  margin-top: 5px;
}

/* Media Queries */
@media (max-width: 992px) {
  .contact-grid {
    grid-template-columns: 1fr;
    gap: 50px;
  }
  
  .contact-info {
    order: 2;
  }
  
  .contact-form-container {
    order: 1;
  }
}

@media (max-width: 768px) {
  .form-grid {
    grid-template-columns: 1fr;
  }
}
</style>
